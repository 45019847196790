import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Flex, Button, Heading, Text } from '@chakra-ui/react'
import { BaymaxIcon } from 'assets/icons'

const BASE_URL = 'https://data.buddysg.com'

function Landing() {
  let navigate = useNavigate()

  const createDataset = async () => {
    const result = await fetch(`${BASE_URL}/create-dataset`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    })
    const response = await result.json()
    console.log(response)

    const { id: newDatasetId } = response
    navigate(newDatasetId)
  }
  return (
    <Flex flexDirection="column" alignItems="center" justifyContent="center" h="100vh">
      <Flex alignItems="center">
        <BaymaxIcon color="#4A61C0" boxSize={20} />
        <Heading ml={5} color="#4A61C0" fontWeight="600">
          data buddy
        </Heading>
      </Flex>
      <Text fontSize="xl" mb={8}>
        Easily create an API to manage your data. A buddy for your data.
      </Text>
      <Button size="lg" bgColor="#4A61C0" color="white" onClick={createDataset}>
        Try It Out
      </Button>
    </Flex>
  )
}

export default Landing
