import React from 'react'
import {
  Box,
  Center,
  Heading,
  Input,
  InputGroup,
  InputLeftAddon,
  Stack,
  Text,
  Link,
  Textarea,
} from '@chakra-ui/react'
import { ExternalLinkIcon } from '@chakra-ui/icons'

import { useParams } from 'react-router-dom'

const BASE_URL = 'https://data.buddysg.com'

const getSampleCurlPostUrl = (datasetId) => `curl -X POST \\
  --url https://data.buddysg.com/${datasetId} \\
  --header 'Content-Type: application/json' \\
  --data '{
	"data": [
		{
			"name": "Ivan",
			"age": "28",
			"fun_fact": "ord lo"
		}
	]
}'`

function Dataset() {
  const { datasetId } = useParams()

  return (
    <Center h="100vh">
      <Box w="xl" borderWidth="1px" borderRadius="lg" overflow="hidden" p={4}>
        <Box mb={6}>
          <Heading color="#4A61C0">Your buddy API is ready</Heading>
          <Text color="gray.700">Use these endpoints to manage your dataset.</Text>
          <Text color="gray.700">Dataset ID: {`${datasetId}`}</Text>
        </Box>
        <Stack mb={6} spacing={4}>
          <Box>
            <Text fontWeight="bold" mb={2}>
              List all rows in the dataset:
            </Text>
            <InputGroup size="sm">
              <InputLeftAddon children="/GET" />
              <Input isReadOnly value={`curl ${BASE_URL}/${datasetId}`} />
            </InputGroup>
          </Box>
          <Box>
            <Text fontWeight="bold" mb={2}>
              Add a row into the dataset:
            </Text>
            <InputGroup size="sm">
              <InputLeftAddon children="/POST" />
              <Textarea h='150px' isReadOnly value={getSampleCurlPostUrl(datasetId)} />
            </InputGroup>
          </Box>

          <Box>
            <Text fontWeight="bold" mb={2}>
              Add this email to FormSG to receive form submissions:
            </Text>
            <InputGroup size="sm">
              <InputLeftAddon children="EMAIL" />
              <Input isReadOnly value={`${datasetId}@mail.buddysg.com`} />
            </InputGroup>
          </Box>
        </Stack>
        <Box>
          <Text as="b">How to use this?</Text>
          <br />
          <Link fontSize="sm" href={`/csv-uploader/${datasetId}`} isExternal>
            Open in CSV Buddy <ExternalLinkIcon mx="2px" />
          </Link>
          <br />
          <Link fontSize="sm" href={`/data-viewer/${datasetId}`} isExternal>
            Open in Table Buddy <ExternalLinkIcon mx="2px" />
          </Link>
          <br />
          <Link fontSize="sm" href={`/data-analytics/${datasetId}`} isExternal>
            Open in Analytics Buddy <ExternalLinkIcon mx="2px" />
          </Link>
          <br />
          <Link fontSize="sm" href={`/data-kanban/${datasetId}`} isExternal>
            Open in Kanban Buddy <ExternalLinkIcon mx="2px" />
          </Link>
          <br />
          <Link fontSize="sm" href={`/data-attendance/${datasetId}`} isExternal>
            Open in Attendance Buddy <ExternalLinkIcon mx="2px" />
          </Link>
        </Box>
      </Box>
    </Center>
  )
}

export default Dataset
