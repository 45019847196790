import React from 'react'
import { Icon, createIcon } from '@chakra-ui/react'

const CircleIcon = (props) => (
  <Icon viewBox="0 0 200 200" {...props}>
    <path fill="currentColor" d="M 100, 100 m -75, 0 a 75,75 0 1,0 150,0 a 75,75 0 1,0 -150,0" />
  </Icon>
)

const BaymaxIcon = createIcon({
  displayName: 'BaymaxIcon',
  viewBox: '0 0 40 32',
  path: [
    <path
      d="M1.46779 14.2161C2.51675 6.85797 9.13632 1.77222 16.253 2.85678L26.9789 4.49136C34.0956 5.57591 39.0145 12.4201 37.9655 19.7782C37.1263 25.6647 31.8307 29.7333 26.1373 28.8657L10.2571 26.4456C4.56371 25.5779 0.628617 20.1026 1.46779 14.2161Z"
      fill="currentColor"
    />,
    <path
      d="M13.7981 14.6353C13.9561 16.044 12.9797 17.3184 11.6173 17.4817C10.2548 17.6451 9.02223 16.6356 8.86423 15.2269C8.70622 13.8182 9.68261 12.5438 11.0451 12.3805C12.4075 12.2171 13.6401 13.2266 13.7981 14.6353Z"
      fill="white"
    />,
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.5028 16.4615C12.3203 16.3635 12.9061 15.5988 12.8113 14.7536C12.7165 13.9084 11.977 13.3027 11.1595 13.4007C10.342 13.4987 9.75619 14.2634 9.851 15.1086C9.9458 15.9538 10.6854 16.5595 11.5028 16.4615ZM11.6173 17.4817C12.9797 17.3184 13.9561 16.044 13.7981 14.6353C13.6401 13.2266 12.4075 12.2171 11.0451 12.3805C9.68261 12.5438 8.70622 13.8182 8.86423 15.2269C9.02223 16.6356 10.2548 17.6451 11.6173 17.4817Z"
      fill="white"
    />,
    <path
      d="M30.0427 16.8233C30.2007 18.232 29.2243 19.5063 27.8619 19.6697C26.4994 19.8331 25.2669 18.8236 25.1089 17.4149C24.9508 16.0062 25.9272 14.7318 27.2897 14.5685C28.6521 14.4051 29.8847 15.4146 30.0427 16.8233Z"
      fill="white"
    />,
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M27.7475 18.6495C28.5649 18.5514 29.1508 17.7868 29.056 16.9416C28.9611 16.0964 28.2216 15.4907 27.4041 15.5887C26.5867 15.6867 26.0008 16.4514 26.0956 17.2966C26.1904 18.1418 26.93 18.7475 27.7475 18.6495ZM27.8619 19.6697C29.2243 19.5063 30.2007 18.232 30.0427 16.8233C29.8847 15.4146 28.6521 14.4051 27.2897 14.5685C25.9272 14.7318 24.9508 16.0062 25.1089 17.4149C25.2669 18.8236 26.4994 19.8331 27.8619 19.6697Z"
      fill="white"
    />,
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M25.6601 17.8658C20.4563 18.4897 16.5033 17.9127 12.7297 16.2462L13.1199 15.3016C16.7115 16.8878 20.4869 17.4521 25.5456 16.8455L25.6601 17.8658Z"
      fill="white"
    />,
  ],
})

export { BaymaxIcon, CircleIcon }
