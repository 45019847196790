import React, { useState } from 'react'
import {
  Box,
  Center,
  Container,
  Heading,
  Input,
  InputGroup,
  InputLeftAddon,
  Text,
  Button,
  FormControl,
  FormErrorMessage,
  HStack,
  useToast,
} from '@chakra-ui/react'
import { Formik, Form, Field } from 'formik'
import _ from 'lodash'
import Dropzone from './Dropzone'
import Papa from 'papaparse'

const uuidV4Pattern = '[0-9a-f]{8}-[0-9a-f]{4}-[4][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$'
const datsetIdPatternTester = new RegExp(uuidV4Pattern)

const BASE_URL = 'https://data.buddysg.com'

function CsvUploader() {
  const [fileData, setFileData] = useState(undefined)
  const toast = useToast()

  function validateDatasetID(value) {
    let error
    if (!value) {
      error = 'DatasetID is required'
    } else if (datsetIdPatternTester.test(value) !== true) {
      error = 'Invalid DatasetID 😱'
    }
    return error
  }

  const uploadFileData = async (values, actions) => {
    // Make call to write enpoint here
    const { datasetId } = values
    console.log('Call to write endpoint for dataset:', datasetId)
    console.log(fileData)

    await fetch(`${BASE_URL}/${datasetId}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        data: fileData,
      }),
    })

    // alert() success or error here
    toast({
      title: 'Success.',
      description: 'Your CSV has been uploaded',
      status: 'success',
      duration: 5000,
      isClosable: true,
      position: 'top',
    })
    console.log('Written to dynamo')
    actions.setSubmitting(false)
  }

  const handleFileAccept = (file) => {
    console.log('Accepted uploaded File')
    console.log(file)
    Papa.parse(file, {
      delimiter: ',',
      newline: '\n',
      header: true,
      skipEmptyLines: true,
      complete: (result) => {
        setFileData(result.data)
      },
    })
  }

  return (
    <Container centerContent maxW="container.xl" h="100vh">
      <Center h="100%" w="100%">
        <Box h="70%" w="100%" borderWidth="1px" borderRadius="lg" overflow="hidden" p={4}>
          <Box h="70%" mb={4}>
            <Heading>Upload CSV data</Heading>
            <Box mt={4} h="100%">
              <Dropzone onFileAccepted={(file) => handleFileAccept(file)} />
            </Box>
            <Text mt={4}>Enter your dataset id below to upload data to</Text>
            <Formik initialValues={{ datasetId: '' }} onSubmit={uploadFileData}>
              {(props) => (
                <Form>
                  <Field name="datasetId" validate={validateDatasetID}>
                    {({ field, form }) => (
                      <FormControl isInvalid={form.errors.datasetId && form.touched.datasetId}>
                        <HStack mt={2} align="center">
                          <InputGroup size="sm">
                            <InputLeftAddon children="Dataset ID" />
                            <Input
                              {...field}
                              id="datasetId"
                              placeholder="eb03d132-6108-49b0-aa74-0c561add1cca"
                            />
                          </InputGroup>
                          <Button
                            size="sm"
                            colorScheme="teal"
                            isLoading={props.isSubmitting}
                            type="submit"
                            disabled={
                              (form.errors.datasetId && form.touched.datasetId) || fileData == null
                            }
                          >
                            Upload
                          </Button>
                        </HStack>
                        <FormErrorMessage>{form.errors.datasetId}</FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                </Form>
              )}
            </Formik>
          </Box>
        </Box>
      </Center>
    </Container>
  )
}

export default CsvUploader
