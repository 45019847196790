/* eslint-disable quote-props */
import React, { useEffect, useState } from 'react'
import {
  Box,
  Center,
  Heading,
  Input,
  InputGroup,
  InputLeftAddon,
  Text,
  Button,
  FormControl,
  FormErrorMessage,
  HStack,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from '@chakra-ui/react'
import { v4 as uuidv4 } from 'uuid'
import { Formik, Form, Field } from 'formik'
import { useParams } from 'react-router-dom'
import _ from 'lodash'
import Board from 'react-trello'
import NavBar from '../../components/NavBar'

const uuidV4Pattern = '[0-9a-f]{8}-[0-9a-f]{4}-[4][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$'
const datsetIdPatternTester = new RegExp(uuidV4Pattern)

const kanbanBoardStyle = {
  backgroundColor: 'transparent',
}
// const BASE_URL = 'http://localhost:3000'
const BASE_URL = 'https://data.buddysg.com'

function DataKanban() {
  const { datasetId: paramDatasetId } = useParams()
  const [currentDatasetId, setCurrentDatasetId] = useState(paramDatasetId)
  const [isFetching, setIsFetching] = useState(false)
  const [datasetHeaders, setDatasetHeaders] = useState(undefined)
  const [datasetData, setDatasetData] = useState(undefined)
  const [kanbanData, setKanbanData] = useState(null)
  const [idToTitleMap, setIdToTitleMap] = useState(new Map())
  const [rowIdOfDatasetInKanbanTable, setRowIdOfDatasetInKanbanTable] = useState(undefined)
  const [kanbanDatasetRow, setKanbanDatasetRow] = useState(undefined)
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [modalData, setModalData] = useState({})
  useEffect(() => {
    if (paramDatasetId) {
      // fetchKanbanLanes(paramDatasetId)
      setIsFetching(true)
      setCurrentDatasetId(paramDatasetId)
      fetchDatasetData(paramDatasetId)
    }
  }, [])

  const transformDataToKanbanData = (data, fetchedKanbanLanes) => {
    // extract email and timestamp out, then store the rest as metadata
    const dataObjects = data.map((item) => {
      const timestamp = item['Timestamp']
      // const timeLabel = timestamp.slice(5, 11) + timestamp.slice(16, 22) + timestamp.slice(25, 28)

      const newData = {
        id: item['row_id'],
        title: item['Email'],
        // description: item['favourite food'],
        description: item['Problem'],
        label: item['Start Date'],
        // label: timeLabel,
        metadata: item,
      }
      return newData
    })

    const firstLaneTitle = fetchedKanbanLanes[0]
    const otherLanes = fetchedKanbanLanes.slice(1)

    const firstLaneObject = {
      id: uuidv4(),
      title: firstLaneTitle,
      label: dataObjects
        .filter(
          (item) => item.metadata.progress == null || item.metadata.progress === firstLaneTitle
        )
        .length.toString(), // Convert to string coz number literal '0' renders weird
      cards: dataObjects
        .filter(
          (item) => item.metadata.progress == null || item.metadata.progress === firstLaneTitle
        )
        .sort((a, b) => a.metadata.Email.localeCompare(b.metadata.Email)),
    }

    const otherLaneObjects = otherLanes.map((lane) => {
      return {
        id: uuidv4(),
        title: lane,
        label: dataObjects.filter((item) => item.metadata.progress === lane).length.toString(),
        cards: dataObjects
          .filter((item) => item.metadata.progress === lane)
          .sort((a, b) => a.metadata.Email.localeCompare(b.metadata.Email)),
        disallowAddingCard: true,
      }
    })

    const newKanbanDataTemp = {
      lanes: [firstLaneObject].concat(otherLaneObjects),
    }

    const kanbanIdAndTitles = newKanbanDataTemp.lanes.map((item) => {
      return {
        id: item.id,
        title: item.title,
      }
    })

    const myMap = new Map()
    kanbanIdAndTitles.forEach((item) => {
      myMap.set(item.id, item.title)
    })
    setIdToTitleMap(myMap)

    return newKanbanDataTemp
  }

  function validateDatasetID(value) {
    let error
    if (!value) {
      error = 'DatasetID is required'
    } else if (datsetIdPatternTester.test(value) !== true) {
      error = 'Invalid DatasetID 😱'
    }
    return error
  }

  const createRowInKanbanLaneTable = async (datasetId) => {
    const dataToInsert = [
      {
        kanban_columns: ['New'],
        columns_for_dataset_id: datasetId,
      },
    ]
    await fetch(`${BASE_URL}/dataset_f5f70641-9119-421f-8075-0071295eeae0`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        data: dataToInsert,
      }),
    })
  }

  const fetchDatasetData = async (datasetId) => {
    // Dataset headers for kanban
    // This dataset specifically stores column data for other datasets
    let kanbanLaneResult = await fetch(`${BASE_URL}/dataset_f5f70641-9119-421f-8075-0071295eeae0`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
    let kanbanResponse = await kanbanLaneResult.json()
    let { data: kanbanLaneData } = kanbanResponse
    let kanbanRowForDataset = kanbanLaneData.find(
      (item) => item.columns_for_dataset_id === datasetId
    )

    // If first time kanban, create a row for it then fetch again
    if (kanbanRowForDataset == null) {
      await createRowInKanbanLaneTable(datasetId)
      kanbanLaneResult = await fetch(`${BASE_URL}/dataset_f5f70641-9119-421f-8075-0071295eeae0`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      })
      kanbanResponse = await kanbanLaneResult.json()
      let { data: kanbanLaneData } = kanbanResponse
      kanbanRowForDataset = kanbanLaneData.find((item) => item.columns_for_dataset_id === datasetId)
    }

    const { kanban_columns: fetchedKanbanLanes, row_id: rowId } = kanbanRowForDataset
    setRowIdOfDatasetInKanbanTable(rowId)
    setKanbanDatasetRow(kanbanRowForDataset)

    // Dataset Data
    const datasetResult = await fetch(`${BASE_URL}/${datasetId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
    const datasetResponse = await datasetResult.json()
    const { data: datasetData } = datasetResponse
    const dataForKanban = transformDataToKanbanData(datasetData, fetchedKanbanLanes)
    setKanbanData(dataForKanban)
    setDatasetHeaders(Object.keys(datasetData[0])) // Using dynamo means some columns are not standard, need to think of how this affects the table headers (union?)
    setDatasetData(datasetData)
    setIsFetching(false)
  }

  const handleDragEnd = async (cardId, sourceLaneId, targetLaneId) => {
    const dataRow = datasetData.find((item) => item.row_id === cardId)
    dataRow.progress = idToTitleMap.get(targetLaneId)
    // update the "progress" state of the card to the target lane id
    await fetch(`${BASE_URL}/${currentDatasetId}/${cardId}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ data: dataRow }),
    })

    // Below will also affect the sorting within the lane
    fetchDatasetData(currentDatasetId) // This is done to reupdate the count label. If huge will take long
  }

  const onLaneAdd = async (params) => {
    const { id, title } = params
    const myMap = new Map(idToTitleMap)
    myMap.set(id, title)
    setIdToTitleMap(myMap)
    const newKanbanDataLanes = kanbanData.lanes.concat({
      id,
      title,
      label: '0',
      cards: [],
      disallowAddingCard: true,
    })

    // Update the kanban dataset columns
    const dataLanesForUpdate = newKanbanDataLanes.map((item) => item.title)
    const newDataRow = kanbanDatasetRow
    newDataRow.kanban_columns = dataLanesForUpdate

    await fetch(
      `${BASE_URL}/dataset_f5f70641-9119-421f-8075-0071295eeae0/${rowIdOfDatasetInKanbanTable}`,
      {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ data: newDataRow }),
      }
    )

    const newKanbanData = {
      lanes: newKanbanDataLanes,
    }
    setKanbanData(newKanbanData)
  }

  const onLaneDelete = async (laneId) => {
    const laneTitle = idToTitleMap.get(laneId)
    const myMap = new Map(idToTitleMap)
    myMap.delete(laneId)
    setIdToTitleMap(myMap)
    // TODO: Ensure lane is empty
    const lanesAfterDeletion = kanbanData.lanes.filter((item) => item.title !== laneTitle)
    const dataLanesForUpdate = lanesAfterDeletion.map((item) => item.title)
    const newDataRow = kanbanDatasetRow
    newDataRow.kanban_columns = dataLanesForUpdate

    await fetch(
      `${BASE_URL}/dataset_f5f70641-9119-421f-8075-0071295eeae0/${rowIdOfDatasetInKanbanTable}`,
      {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ data: newDataRow }),
      }
    )

    const newKanbanData = {
      lanes: lanesAfterDeletion,
    }
    setKanbanData(newKanbanData)
  }

  // TODO: Change order in the kanban lanes
  const handleLaneDragEnd = async (removedIndex, addedIndex, payload) => {
    console.log('lane drag end params', removedIndex, addedIndex, payload)
  }

  const onCardAdd = async (card, laneId) => {
    const dataToInsert = [
      {
        Email: card.title,
        Problem: card.description,
        'Start Date': card.label,
      },
    ]

    await fetch(`${BASE_URL}/${currentDatasetId}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        data: dataToInsert,
      }),
    })

    // Below will also affect the sorting within the lane
    fetchDatasetData(currentDatasetId) // This is done to reupdate the count label. If huge will take long
  }

  const onCardClick = (cardId, metadata, laneId) => {
    setModalData(metadata)
    onOpen()
  }

  const formSubmit = async (values, actions) => {
    // await new Promise((r) => setTimeout(r, 3000)) // Simulate network call lag
    const { datasetId } = values
    setCurrentDatasetId(datasetId)
    fetchDatasetData(datasetId)
    actions.setSubmitting(false)
  }

  function validateDatasetID(value) {
    let error
    if (!value) {
      error = 'DatasetID is required'
    } else if (datsetIdPatternTester.test(value) !== true) {
      error = 'Invalid DatasetID 😱'
    }
    return error
  }

  return (
    <Center>
      <Box width="80vw">
        <NavBar subpage="table" datasetId={paramDatasetId} />

        <Box my={10}>
          <Heading>View your Kanban</Heading>
          <Text>Enter your dataset id below to view your data</Text>
          <Formik initialValues={{ datasetId: paramDatasetId ?? '' }} onSubmit={formSubmit}>
            {(props) => (
              <Form>
                <Field name="datasetId" validate={validateDatasetID}>
                  {({ field, form }) => (
                    <FormControl isInvalid={form.errors.datasetId && form.touched.datasetId}>
                      <HStack mt={4} align="center">
                        <InputGroup size="sm">
                          <InputLeftAddon children="Dataset ID" />
                          <Input
                            {...field}
                            disabled={paramDatasetId}
                            id="datasetId"
                            placeholder="dataset_79902115-ab7c-4df9-904f-ffa8f4ae8db2"
                          />
                        </InputGroup>
                        <Button
                          size="sm"
                          colorScheme="teal"
                          isLoading={props.isSubmitting || isFetching}
                          type="submit"
                        >
                          Fetch
                        </Button>
                      </HStack>
                      <FormErrorMessage>{form.errors.datasetId}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
              </Form>
            )}
          </Formik>
        </Box>

        <Center>
          <Box w="100%" borderWidth="1px" borderRadius="lg" p={4}>
            {/* <Heading>View your Kanban</Heading> */}
            {kanbanData && (
              <Board
                editable
                canAddLanes
                hideCardDeleteIcon
                draggable
                style={kanbanBoardStyle}
                data={kanbanData}
                handleDragEnd={handleDragEnd}
                onLaneAdd={onLaneAdd}
                handleLaneDragEnd={handleLaneDragEnd}
                onLaneDelete={onLaneDelete}
                onCardAdd={onCardAdd}
                onCardClick={onCardClick}
              />
            )}
          </Box>
        </Center>
      </Box>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Item Data</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {Object.keys(modalData).map((item) => {
              return (
                <Box mb={3}>
                  <Heading size="xs">{item}</Heading>
                  <Text>{modalData[item]}</Text>
                </Box>
              )
            })}
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Center>
  )
}

export default DataKanban
