import React, { useEffect, useState } from 'react'
import {
  Box,
  Container,
  Heading,
  Input,
  InputGroup,
  InputLeftAddon,
  Text,
  Tag,
  Button,
  FormControl,
  FormErrorMessage,
  HStack,
  Flex,
  List,
  ListItem,
  Divider,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Select,
} from '@chakra-ui/react'
import { ArrowBackIcon } from '@chakra-ui/icons'
import NavBar from '../../components/NavBar'
import { Formik, Form, Field } from 'formik'
import _ from 'lodash'
import { useNavigate, useParams } from 'react-router-dom'
import { Pie, Bar, Doughnut } from 'react-chartjs-2'
import Chart from 'chart.js/auto'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import TableViewer from '../../components/TableViewer'
import { DateTime } from 'luxon'

Chart.register(ChartDataLabels)
Chart.defaults.set('plugins.datalabels', {
  color: 'black',
  formatter: (value, ctx) => {
    let sum = 0
    let dataArr = ctx.chart.data.datasets[0].data
    dataArr.map((data) => {
      sum += data
    })
    let percentage = ((value * 100) / sum).toFixed(2) + '%'
    return percentage
  },
})
const BASE_URL = 'https://data.buddysg.com'

const pieChartOptions = {
  maintainAspectRatio: false,
  plugins: {
    datalabels: {
      anchor: 'end',
      align: 'start',
      offset: 12,
      display: 'auto',
      color: 'black',
    },
    legend: {
      display: true,
      position: 'bottom',
      labels: {
        // This more specific font property overrides the global property
        font: {
          size: 12,
          weight: 500,
        },
      },
    },
  },
}

const barChartOptions = {
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false,
      position: 'top',
      labels: {
        // This more specific font property overrides the global property
        font: {
          size: 12,
          weight: 500,
        },
      },
    },
  },
}

// My old
const COLORS = [
  '#B7C0E6',
  '#9BEBD7',
  '#E8C1C1',
  '#FCECB3',
  '#B3CCC6',
  '#B3CBD6',
  '#D3C1DC',
  '#FCD4BD',
  '#DEB097',
]

const uuidV4Or5Pattern = '[0-9a-f]{8}-[0-9a-f]{4}-[45][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$'
const datsetIdPatternTester = new RegExp(uuidV4Or5Pattern)

function DataAnalytics() {
  const navigate = useNavigate()
  const { datasetId: paramDatasetId } = useParams()
  const [isFetching, setIsFetching] = useState(false)
  const [datasetHeaders, setDatasetHeaders] = useState(undefined)
  const [groupableHeaders, setGroupableHeaders] = useState(undefined)
  const [datasetData, setDatasetData] = useState(undefined)
  const [graphData, setGraphData] = useState(undefined)
  const [graphPreferences, setGraphPreferences] = useState(new Map())
  const [lastFetched, setLastFetched] = useState(null)

  useEffect(() => {
    if (paramDatasetId) {
      setIsFetching(true)
      fetchDatasetData(paramDatasetId)
    }
  }, [])

  function validateDatasetID(value) {
    let error
    if (!value) {
      error = 'DatasetID is required'
    } else if (datsetIdPatternTester.test(value) !== true) {
      error = 'Invalid DatasetID 😱'
    }
    return error
  }

  const sortLabelsAndCounts = (labels, counts) => {
    const arrayOfObj = labels.map(function (label, index) {
      return {
        label: label,
        count: counts[index],
      }
    })
    const sortedArrayOfObj = arrayOfObj.sort((a, b) => (b.count > a.count ? 1 : -1))
    let newLabels = []
    let newCounts = []
    sortedArrayOfObj.forEach((obj) => {
      newLabels.push(obj.label)
      newCounts.push(obj.count)
    })
    return {
      labels: newLabels,
      counts: newCounts,
    }
  }

  const getAllGraphData = (groupableHeaders, actualData) => {
    let initialGraphPreferenceMap = new Map()
    const allData = groupableHeaders.map((header, index) => {
      const allResultsForHeader = actualData.map((item) => item[header])

      // NOTE: This line splits values of the result comma - this is specifically for Checkbox data from FormSG.
      // This might need to change in the future
      let flattenedResultsForHeader = _.flatMap(allResultsForHeader, (item) =>
        _.split(item, ',').map((item) => item.trim())
      )

      const countMap = new Map()
      flattenedResultsForHeader.forEach((result) => {
        if (countMap.has(result)) {
          countMap.set(result, countMap.get(result) + 1)
        } else {
          countMap.set(result, 1)
        }
      })
      const labels = Array.from(countMap.keys()).sort()
      const counts = labels.map((label) => countMap.get(label))

      let { labels: sortedLabels, counts: sortedCounts } = sortLabelsAndCounts(labels, counts)

      const dataObject = {
        labels: sortedLabels,
        datasets: [
          {
            label: header,
            data: sortedCounts,
            backgroundColor: COLORS,
            hoverOffset: 20,
          },
        ],
      }

      // If 3 or less labels -> pie
      // If 4-10 labels -> bar
      // If 11 or more labels -> list
      if (labels.length < 4) {
        initialGraphPreferenceMap.set(index, 'pie')
      } else if (labels.length >= 4 && labels.length < 11) {
        initialGraphPreferenceMap.set(index, 'bar')
      } else {
        initialGraphPreferenceMap.set(index, 'list')
      }
      return {
        chartData: dataObject,
        listData: {
          sortedLabels,
          sortedCounts,
        },
      }
    })
    setGraphPreferences(initialGraphPreferenceMap)
    return allData
  }

  const formSubmit = async (values, actions) => {
    // await new Promise((r) => setTimeout(r, 3000)) // Simulate network call lag
    const { datasetId } = values
    fetchDatasetData(datasetId)
    actions.setSubmitting(false)
  }

  const fetchDatasetData = async (datasetId) => {
    const result = await fetch(`${BASE_URL}/${datasetId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
    const response = await result.json()
    const { data } = response
    console.log('Data received')
    // console.log(data)

    // Use reduce to collect all keys in a Set
    const allKeys = data.reduce((keys, obj) => {
      Object.keys(obj).forEach((key) => keys.add(key))
      return keys
    }, new Set())

    // Convert Set to an array
    const uniqueKeysArray = Array.from(allKeys)

    const groupableHeaders = uniqueKeysArray.filter(
      (header) =>
        !['row_id', 'dataset_id', 'dataset_row_index', 'Timestamp', 'Response ID'].includes(header),
    )
    groupableHeaders.sort()
    const allGraphData = getAllGraphData(groupableHeaders, data)
    setGroupableHeaders(groupableHeaders)
    setGraphData(allGraphData)

    // Union the columns of all rows to get all possible columns
    const columnSet = new Set()
    data.forEach((item) => {
      Object.keys(item).forEach((column) => columnSet.add(column))
    })
    const sortedHeaders = Array.from(columnSet).sort()
    setDatasetHeaders(sortedHeaders)
    setDatasetData(data)

    setLastFetched(DateTime.now().toLocaleString(DateTime.TIME_24_WITH_SECONDS))
    setIsFetching(false)
  }

  const renderTable = () => {
    return <TableViewer datasetData={datasetData} enableCheckbox={false}></TableViewer>
  }

  const handleChartSwtich = (event) => {
    const index = parseInt(event.target.id.split('_')[1])
    const newGraphType = event.target.value
    var newMap = new Map(graphPreferences)
    newMap.set(index, newGraphType)
    setGraphPreferences(newMap)
  }

  const renderSpecificChart = (index, data) => {
    switch (graphPreferences.get(index)) {
      case 'list':
        return (
          <List w="100%" h="300px" overflow="scroll" spacing={3}>
            {data.listData.sortedLabels.map((label, index) => (
              <ListItem p={3} borderRadius="5px" bg="gray.100">
                {label}: {data.listData.sortedCounts[index]}
              </ListItem>
            ))}
          </List>
        )
      case 'bar':
        return <Bar options={barChartOptions} data={data.chartData} />
      case 'pie':
        return <Pie options={pieChartOptions} data={data.chartData} />
      case 'doughnut':
        return <Doughnut options={pieChartOptions} data={data.chartData} />
      default:
        return 'no chart'
    }
  }

  const renderChartForIndex = (header, index) => {
    return (
      <Box mb={8}>
        <HStack>
          <Text color="#4A61C0" fontSize="xl" fontWeight="500" my={5}>
            {header}
          </Text>
          {/* <Spacer /> */}
          <FormControl w="150px" alignItems="center">
            <Select
              variant="unstyled"
              ml={4}
              onChange={handleChartSwtich}
              w="150px"
              size="md"
              value={graphPreferences.get(index)}
              id={`chart-select_${index}`}
            >
              <option value="bar">Bar Chart View</option>
              <option value="pie">Pie Chart View</option>
              <option value="doughnut">Doughnut View</option>
              <option value="list">List View</option>
            </Select>
          </FormControl>
        </HStack>
        <Flex h="300px">{renderSpecificChart(index, graphData[index])}</Flex>
      </Box>
    )
  }
  const renderCharts = () => {
    return (
      <>
        {groupableHeaders.map((header, index) => {
          return <>{renderChartForIndex(header, index)}</>
        })}
      </>
    )
  }

  // Back arrow to datasets page for login type of databuddy
  // const handleBackArrowClick = () => {
  //   if (paramDatasetId) {
  //     navigate(`/dashboard/projects/${paramDatasetId}`)
  //   }
  // }

  // const { getUser } = useUser()

  return (
    <Box>
      <NavBar subpage="analytics" datasetId={paramDatasetId} />
      <Container pt="64px" mb={14} centerContent maxW="container.xl">
        <Box w="100%" p={4}>
          {/* {getUser() && (
            <Button
              onClick={handleBackArrowClick}
              mb={8}
              size="lg"
              bg="none"
              color="#4A61C0"
              leftIcon={<ArrowBackIcon />}
            >
              Back to data projects
            </Button>
          )} */}

          <Box mb={4}>
            <Tag my={3} color="white" bg="#445072">
              Analytics buddy
            </Tag>
            <Text mb={10} size="md" color="#69738E">
              last updated {lastFetched ? lastFetched : '-'}
            </Text>
            <Formik initialValues={{ datasetId: paramDatasetId ?? '' }} onSubmit={formSubmit}>
              {(props) => (
                <Form>
                  <Field name="datasetId" validate={validateDatasetID}>
                    {({ field, form }) => (
                      <FormControl isInvalid={form.errors.datasetId && form.touched.datasetId}>
                        <HStack mt={4} align="center">
                          <InputGroup size="sm">
                            <InputLeftAddon children="Dataset ID" />
                            <Input
                              {...field}
                              disabled={paramDatasetId}
                              id="datasetId"
                              placeholder="dataset_79902115-ab7c-4df9-904f-ffa8f4ae8db2"
                            />
                          </InputGroup>
                          <Button
                            size="sm"
                            bg="#4A61C0"
                            color="white"
                            isLoading={props.isSubmitting || isFetching}
                            type="submit"
                          >
                            Fetch
                          </Button>
                        </HStack>
                        <FormErrorMessage>{form.errors.datasetId}</FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                </Form>
              )}
            </Formik>
            <Divider my={12} />
            {datasetData && (
              <Tabs mt={8} defaultIndex={0}>
                <TabList>
                  <Tab letterSpacing={1.5} fontWeight="500" fontSize="sm">
                    CHARTS
                  </Tab>
                  <Tab letterSpacing={1.5} fontWeight="500" fontSize="sm">
                    RESPONSES ({datasetData.length})
                  </Tab>
                </TabList>

                <TabPanels>
                  <TabPanel>
                    <Box>{renderCharts()}</Box>
                  </TabPanel>
                  <TabPanel>
                    <Box mt={5}>
                      <Heading mb={10} size="lg">
                        Responses: {datasetData.length}
                      </Heading>
                      <Box h="60vh" overflow="scroll">
                        {renderTable()}
                      </Box>
                    </Box>
                  </TabPanel>
                </TabPanels>
              </Tabs>
            )}
          </Box>
        </Box>
      </Container>
    </Box>
  )
}

export default DataAnalytics
