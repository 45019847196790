import React, { useEffect, useState } from 'react'
import {
  Box,
  Center,
  Container,
  Heading,
  Input,
  InputGroup,
  InputLeftAddon,
  Text,
  Button,
  FormControl,
  FormErrorMessage,
  HStack,
  Divider,
  Tag,
} from '@chakra-ui/react'
import { useParams } from 'react-router-dom'
import { Formik, Form, Field } from 'formik'
import { DateTime } from 'luxon'

// Components
import NavBar from '../../components/NavBar'
import TableViewer from '../../components/TableViewer'

const uuidV4Or5Pattern = '[0-9a-f]{8}-[0-9a-f]{4}-[45][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$'
const datsetIdPatternTester = new RegExp(uuidV4Or5Pattern)

const BASE_URL = 'https://data.buddysg.com'

function DataViewer() {
  const { datasetId: paramDatasetId } = useParams()
  const [isFetching, setIsFetching] = useState(false)
  const [datasetData, setDatasetData] = useState([])
  const [lastFetched, setLastFetched] = useState(null)

  useEffect(async () => {
    if (paramDatasetId) {
      setIsFetching(true)
      const data = await fetchDatasetData(paramDatasetId)
      setDatasetData(data)
      setIsFetching(false)
    }
  }, [])

  function validateDatasetID(value) {
    let error
    if (!value) {
      error = 'DatasetID is required'
    } else if (datsetIdPatternTester.test(value) !== true) {
      error = 'Invalid DatasetID 😱'
    }
    return error
  }

  const formSubmit = async (values, actions) => {
    const { datasetId } = values
    const data = await fetchDatasetData(datasetId)
    setDatasetData(data)
    actions.setSubmitting(false)
  }

  const fetchDatasetData = async (datasetId) => {
    const result = await fetch(`${BASE_URL}/${datasetId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
    const response = await result.json()
    const { data } = response

    setLastFetched(DateTime.now().toLocaleString(DateTime.TIME_24_WITH_SECONDS))

    return data
  }

  return (
    <Box>
      <NavBar subpage="table" datasetId={paramDatasetId} />
      <Container pt="64px" mb={14} centerContent maxW="container.xl">
        <Center w="100%">
          <Box w="100%" overflow="hidden" p={4}>
            <Box mb={4}>
              <Tag my={3} color="white" bg="#445072">
                Table buddy
              </Tag>
              <Text mb={10} size="md" color="#69738E">
                last updated {lastFetched ? lastFetched : '-'}
              </Text>
              <Formik initialValues={{ datasetId: paramDatasetId ?? '' }} onSubmit={formSubmit}>
                {(props) => (
                  <Form>
                    <Field name="datasetId" validate={validateDatasetID}>
                      {({ field, form }) => (
                        <FormControl isInvalid={form.errors.datasetId && form.touched.datasetId}>
                          <HStack mt={4} align="center">
                            <InputGroup size="sm">
                              <InputLeftAddon children="Dataset ID" />
                              <Input
                                {...field}
                                disabled={paramDatasetId}
                                id="datasetId"
                                placeholder="dataset_79902115-ab7c-4df9-904f-ffa8f4ae8db2"
                              />
                            </InputGroup>
                            <Button
                              size="sm"
                              bg="#4A61C0"
                              color="white"
                              isLoading={props.isSubmitting || isFetching}
                              type="submit"
                            >
                              Fetch
                            </Button>
                          </HStack>
                          <FormErrorMessage>{form.errors.datasetId}</FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                  </Form>
                )}
              </Formik>
              <Divider my={4} />
              {datasetData && (
                <>
                  <Heading mb={4} size="lg">
                    Rows: {datasetData.length}
                  </Heading>
                </>
              )}

              <Box h="63vh" overflow="scroll">
                <TableViewer datasetData={datasetData} enableCheckbox={false}></TableViewer>
              </Box>
            </Box>
          </Box>
        </Center>
      </Container>
    </Box>
  )
}

export default DataViewer
