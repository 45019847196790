import React from 'react'
import { Avatar, Spacer, HStack, Flex, Text, Link } from '@chakra-ui/react'
import { ChevronDownIcon, ExternalLinkIcon } from '@chakra-ui/icons'

import { BaymaxIcon } from '../../assets/icons'

// const user = 'tahlitha@open.gov.sg'
const user = null

const NavBar = ({ subpage, datasetId }) => {
  return (
    <Flex
      h="64px"
      position="fixed"
      as="nav"
      w="100%"
      css={{ backdropFilter: 'blur(10px)' }}
      zIndex="banner"
      align="center"
      borderBottom="1px"
      borderColor="#E1E2E4"
    >
      <HStack ml={8}>
        {subpage ? (
          <>
            <Text ml={5} fontSize="20px" color="#4A61C0" fontWeight="600">
              data buddy
            </Text>
            <BaymaxIcon color="#A2A8B9" boxSize={8} />
            <Text ml={5} fontSize="20px" color="#445072" fontWeight="600">
              {subpage}
            </Text>
          </>
        ) : (
          <>
            <BaymaxIcon color="#4A61C0" boxSize={8} />
            <Text ml={5} fontSize="20px" color="#4A61C0" fontWeight="600">
              data buddy
            </Text>
          </>
        )}
      </HStack>
      <Spacer />
      {user && (
        <HStack mr={8}>
          <Text mr={3} color="#445072" fontWeight="500" fontSize="lg">
            {user}
          </Text>
          <Avatar bg="#4A61C0" color="white" size="md" name={user} />
          <ChevronDownIcon boxSize={6} />
        </HStack>
      )}
      <Spacer />
      {datasetId && (
        <HStack mr={8}>
          <Text ml={5} fontSize="20px" color="#4A61C0" fontWeight="600">
            my buddies
          </Text>
          <BaymaxIcon color="#A2A8B9" boxSize={8} />
          <Link fontSize="sm" href={`/csv-uploader/${datasetId}`} isExternal>
            csv <ExternalLinkIcon mx="2px" />
          </Link>
          <Link fontSize="sm" href={`/data-viewer/${datasetId}`} isExternal>
            table <ExternalLinkIcon mx="2px" />
          </Link>
          <Link fontSize="sm" href={`/data-analytics/${datasetId}`} isExternal>
            analytics <ExternalLinkIcon mx="2px" />
          </Link>
          <Link fontSize="sm" href={`/data-kanban/${datasetId}`} isExternal>
            kanban <ExternalLinkIcon mx="2px" />
          </Link>
          <Link fontSize="sm" href={`/data-attendance/${datasetId}`} isExternal>
            attendance <ExternalLinkIcon mx="2px" />
          </Link>
        </HStack>
      )}
    </Flex>
  )
}

export default NavBar
