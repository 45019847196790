import React, { useMemo, useEffect, useState } from 'react'
import { Center, Table, Thead, Tbody, Tr, Th, Td, Checkbox, Link, chakra } from '@chakra-ui/react'
import { ExternalLinkIcon, TriangleDownIcon, TriangleUpIcon } from '@chakra-ui/icons'
import _ from 'lodash'
import { useTable, useSortBy } from 'react-table'

const BASE_URL = 'https://data.buddysg.com'

const isUrl = (urlString) => {
  try {
    return Boolean(new URL(urlString))
  } catch (e) {
    return false
  }
}

const updateDatasetRow = async (datasetId, rowId, data) => {
  await fetch(`${BASE_URL}/${datasetId}/${rowId}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      data,
    }),
  })
}

function TableViewer({ datasetData, enableSearch, enableCheckbox }) {
  const CHECKBOX = '_checkbox'
  const [checkedItems, setCheckedItems] = useState({})
  const [datasetColumns, setDatasetColumns] = useState([])

  useEffect(() => {
    const columns = deriveColumns(datasetData, enableCheckbox)
    setDatasetColumns(columns)

    // Pad false if no checkbox property and checkbox enabled
    if (enableCheckbox) {
      padDataRowsWithCheckbox(datasetData)
    }
  }, [datasetData])

  const data = React.useMemo(() => datasetData, [datasetData])
  const columns = useMemo(() => datasetColumns, [datasetColumns])

  const padDataRowsWithCheckbox = (datasetData) => {
    datasetData.forEach((row) => {
      if (!(CHECKBOX in row)) {
        row[CHECKBOX] = false // if there is no _checkbox property, add it as false
      }
    })
  }

  const updateCheckbox = async (value, index) => {
    setCheckedItems({
      ...checkedItems,
      [index]: value,
    })
    const record = datasetData[index]

    await updateDatasetRow(record.dataset_id, record.row_id, {
      ...record,
      [CHECKBOX]: value,
    })
  }

  const deriveColumns = (data, enableCheckbox) => {
    // Union the columns of all rows to get all possible columns
    const columnSet = new Set()
    data.forEach((item, index) => {
      return Object.keys(item).forEach((column) => columnSet.add(column))
    })

    // Remove metadata columns
    columnSet.delete('row_id')
    columnSet.delete('dataset_row_index')
    columnSet.delete('dataset_id')

    // Remove checkbox column as we will render this separately
    columnSet.delete(CHECKBOX)

    const renderCell = (cell) => {
      // For some reason some columns the value is undefined, cant figure yet just access the thing itself
      const originalString = cell.row.original[cell.column.Header]
      if (isUrl(originalString)) {
        return (
          <Link href={originalString} isExternal>
            {originalString} <ExternalLinkIcon mx="2px" />
          </Link>
        )
      } else {
        return originalString || null // Returning undefined will cause render to break
      }
    }

    const columnsArray = Array.from(columnSet).sort()
    const columnsForReactTable = _.map(columnsArray, (column) => {
      return {
        Header: column,
        accessor: column,
        Cell: renderCell,
      }
    })

    // Add Checkbox to the front with custom cell render
    if (enableCheckbox) {
      const checkboxDisplay = ({ row, value }) => {
        // Each is a Cell object
        // https://stackoverflow.com/questions/71084987/why-should-i-use-cell-rendercell-instead-of-cell-value-in-react-table
        return (
          <Center>
            <Checkbox
              outline="1px solid black"
              defaultChecked={_.isUndefined(value) ? false : value}
              isChecked={checkedItems[row.index]}
              onChange={(e) => updateCheckbox(e.target.checked, row.index)}
              size="lg"
            ></Checkbox>
          </Center>
        )
      }

      columnsForReactTable.unshift({
        sortType: 'basic', // To sort booleans too
        Header: CHECKBOX,
        accessor: CHECKBOX,
        Cell: checkboxDisplay,
      })
    }

    return columnsForReactTable
  }

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    { columns, data },
    useSortBy
  )

  if (datasetData.length === 0 || datasetColumns.length === 0) {
    return (
      <Center h="100%">
        <p>No data to display</p>
      </Center>
    )
  } else {
    return (
      <Table variant="striped" size="md" {...getTableProps()}>
        <Thead>
          {headerGroups.map((headerGroup) => (
            <Tr {...headerGroup.getHeaderGroupProps()} bg="gray.700">
              {headerGroup.headers.map((column) => (
                <Th
                  fontWeight="550"
                  textTransform="none"
                  textColor="white"
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  isNumeric={column.isNumeric}
                >
                  {column.render('Header')}
                  <chakra.span pl="4">
                    {column.isSorted ? (
                      column.isSortedDesc ? (
                        <TriangleDownIcon aria-label="sorted descending" />
                      ) : (
                        <TriangleUpIcon aria-label="sorted ascending" />
                      )
                    ) : null}
                  </chakra.span>
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row)
            return (
              <Tr {...row.getRowProps()}>
                {row.cells.map((cell) => (
                  <Td {...cell.getCellProps()} isNumeric={cell.column.isNumeric}>
                    {cell.render('Cell')}
                  </Td>
                ))}
              </Tr>
            )
          })}
        </Tbody>
      </Table>
    )
  }
}

export default TableViewer
