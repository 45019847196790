import React from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'

// Main pages
import Landing from '../pages/landing'
import Dataset from '../pages/dataset'

// Data buddies
import DataKanban from '../buddies/kanban'
import DataAttendance from '../buddies/attendance'
import DataAnalytics from '../buddies/analytics'
import DataViewer from '../buddies/table'
import CsvUploader from '../buddies/csv'

function App() {
  return (
    <Router>
      <Routes>
        {/* Main Pages */}
        <Route path="/" element={<Landing />} />
        <Route path="/:datasetId" element={<Dataset />} />

        {/* Analytics Buddy */}
        <Route path="/data-analytics" element={<DataAnalytics />} />
        <Route path="/data-analytics/:datasetId" element={<DataAnalytics />} />

        {/* Table Buddy */}
        <Route path="/data-viewer" element={<DataViewer />} />
        <Route path="/data-viewer/:datasetId" element={<DataViewer />} />

        {/* Csv Buddy */}
        <Route path="/csv-uploader" element={<CsvUploader />} />
        <Route path="/csv-uploader/:datasetId" element={<CsvUploader />} />

        {/* Kanban Buddy */}
        <Route path="/data-kanban" element={<DataKanban />} />
        <Route path="/data-kanban/:datasetId" element={<DataKanban />} />

        {/* Attendance Buddy */}
        <Route path="/data-attendance" element={<DataAttendance />} />
        <Route path="/data-attendance/:datasetId" element={<DataAttendance />} />

        {/* <Route path="/dashboard/*" element={<Dashboard />} /> Old dashboard code, for prototyping data buddy with login*/}
      </Routes>
    </Router>
  )
}

export default App
